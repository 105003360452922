@import './materialize-variables.scss';
$small-phone-up: 375px !default;
$small-phone-screen: 374px !default;
$small-phone-and-down: 'only screen and (max-width : #{$small-phone-screen})' !default;
$small-only: 'only screen and (min-width : #{$small-phone-up}) and (max-width : #{$small-screen})' !default;
$table-font-size: 0.8rem;
$exact-large-only: 'only screen and (min-width : #{$medium-screen-up}) and (max-width : #{$large-screen})' !default;
$xbox-green: #5dc21e;
$ps-blue: #003791;
$pc-blue: #0078d7;
$stadia-orange: #fc4a1f;
$drr-color: #03a9f4;
$emblem-size: 6rem;
