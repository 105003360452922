@use "sass:math";
@import './drr-variables.scss';

html {
  scrollbar-gutter: stable;
}

body {
  // Override padding added by mui's scrollLock becuase it conflicts with scrollbar-gutter: stable
  // https://github.com/mui/material-ui/issues/34513
  padding-right: 0 !important;
}

table {
  font-size: $table-font-size;
}

.static-card-title {
  margin: 0;
  line-height: 1.5;
  font-size: 1.7rem;

  @media #{$small-and-down} {
    font-size: 1.4rem;
  }
}

.static-card-subtitle {
  font-size: 1rem;

  @media #{$small-and-down} {
    font-size: 0.8rem;
  }
}

.d-flex {
  display: flex;
}

.mw-0 {
  min-width: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inherit-icon-size {
  font-size: inherit;
}

.label-heading {
  font-size: 0.9rem;
}

.top-spacing {
  margin-top: 0.5rem;
}

.drr-color {
  background-color: $drr-color;
}

span.badge.new {
  background-color: $drr-color;
}

.progress {
  background-color: #b3e5fc;
  .indeterminate {
    background-color: #29b6f6;
  }
}

.success-color {
  color: $success-color;
}

.error-color {
  color: $error-color;
}

.xbox-green {
  background-color: $xbox-green !important;
}

.ps-blue {
  background-color: $ps-blue !important;
}

.pc-blue {
  background-color: $pc-blue !important;
}

.stadia-orange {
  background-color: $stadia-orange !important;
}

.clickable {
  cursor: pointer;
}

.hide-on-exact-large-only {
  @media #{$exact-large-only} {
    display: none !important;
  }
}

.dir-small-col {
  @media #{$small-and-down} {
    flex-direction: column;
  }
}

.drr-container {
  margin: 0 auto;
  max-width: 1300px;
  width: 95%;
}

.drr-container .raid-row {
  margin-left: (-1 * math.div($gutter-width, 2));
  margin-right: (-1 * math.div($gutter-width, 2));
}

.small-bottom-margin {
  margin-bottom: 0.5rem;
}

.row-bottom-margin {
  margin-bottom: 20px !important;
}

.card-top-margin {
  margin-top: 0.75rem;
}

.no-margin {
  margin: 0rem;
}

.page-flexbox-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.black-text-shadow {
  text-shadow: black 0px 0px 1rem;
}

thead {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.drr-dark {
  color: white;

  .card {
    background-color: #252526;
  }

  table.striped > tbody > tr {
    border-bottom: 1px solid #777777;

    &:nth-child(odd) {
      background-color: #333333;
    }
  }

  .leaderboard-highlighted-entry {
    background-color: #1a237e !important;
  }

  .leaderboard-header {
    border-color: rgba(81, 81, 81, 1);
  }

  thead {
    border-bottom-color: rgba(255, 255, 255, 0.12);
  }

  // handle ps icon contrast issue
  svg[fill='#003791'] {
    fill: white;
  }
  svg[fill='#000001'] {
    fill: white;
  }
}

.side-container {
  @media (min-width: 1740px) {
    min-height: 706px;
  }
}

// undo materialize styles
button:focus {
  background-color: unset;
}

// sentry dialog fixes
.sentry-error-embed {
  color-scheme: normal;

  .form-submit .btn {
    height: inherit;
    line-height: inherit;
  }
}

// fix for netlify preview
iframe {
  color-scheme: normal;
}
