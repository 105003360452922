$error-color: #f44336 !default;
$success-color: #4caf50 !default;
$gutter-width: 1.5rem !default;
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default;
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default;
$extra-large-and-up: 'only screen and (min-width : #{$large-screen-up})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default;
