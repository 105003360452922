@import '../../drr-variables.scss';
.destiny-card {
  max-width: 5 * $emblem-size;
  height: $emblem-size;
  margin-bottom: 0rem;
  display: flex;
}

.card-length {
  max-width: 5 * $emblem-size;
}

.destiny-card-emblem {
  width: $emblem-size;
  height: $emblem-size;
  border-radius: 2px;
}

.destiny-card-content {
  color: white;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}
