.sparkle-wrapper {
  position: absolute;
  display: block;
  z-index: 2;
  pointer-events: none;
  @media (prefers-reduced-motion: no-preference) {
    animation: comeIn 450ms forwards alternate;
    animation-iteration-count: 2;
  }
}

.sparkle-svg {
  display: block;
  @media (prefers-reduced-motion: no-preference) {
    animation: spin 1000ms linear;
  }
}

.iteration-count-1 {
  animation-iteration-count: 1;
}

@keyframes comeIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
