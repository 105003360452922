@import '../drr-variables.scss';
.drr-footer {
  color: rgba(255, 255, 255, 0.8);
}

.small-top-margin {
  margin-top: 0.5rem !important;
}

.footer-icon {
  margin-right: 0.5rem;
}

.support-us {
  margin-right: 2rem;
}

footer a {
  color: white;
}

footer {
  @media #{$small-and-down} {
    font-size: 0.8rem;
  }
}
