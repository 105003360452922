@import '../../drr-variables.scss';
$site-logo-size: 80%;
.search-bar {
  flex-grow: 1;
  overflow: hidden;
  input:-webkit-autofill {
    &:focus {
      box-shadow: 0 0 0px 1000px white inset !important;
      -webkit-text-fill-color: #444 !important;
    }
    &:not(:focus) {
      box-shadow: 0 0 0px 1000px $drr-color inset !important;
      -webkit-text-fill-color: white !important;
    }
  }
}

.search-button {
  @media #{$small-and-down} {
    font-size: 1.5rem !important;
  }
}

.search-bar input {
  -webkit-appearance: none;
}

input[type='search']:not(:focus) ~ .search-button {
  color: inherit !important;
}

.site-logo-name {
  img {
    float: left;
    margin-right: 0.5rem;
    height: $site-logo-size;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  flex-shrink: 0;
  height: inherit;
  margin-right: 0.75rem;
}
.nav-wrapper.drr-container {
  display: flex;
}
